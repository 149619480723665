export default {
  register(hub, connection, startedPromise) {
    connection.on(
      'PdpDocumentSaved',
      (fileName, documentId, uploadDateUtc, downloadUrl) => {
        hub.$emit('pdp-document-saved', {
          fileName,
          documentId,
          uploadDateUtc,
          downloadUrl
        });
      }
    );

    hub.joinProcessOverviewGroup = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() =>
          connection.invoke('JoinProcessOverviewGroup', correlationId)
        )
        .catch(console.error);
    };

    hub.leaveProcessOverviewGroup = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() =>
          connection.invoke('LeaveProcessOverviewGroup', correlationId)
        )
        .catch(console.error);
    };
  }
};
