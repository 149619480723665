import Vue from 'vue';
import VueRouter from 'vue-router';

import { feedback } from '@/router/feedback-routes.js';
import { employees } from '@/router/employees-routes.js';
import { workflows } from './workflow-routes';
import { targets } from './target-routes';
import { reportingRoutes } from './reporting-routes.js';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:slug/dashboard',
    name: 'Dashboard',
    meta: {
      breadcrumbs: [],
      title: 'Dashboard'
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/:slug/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/callback',
    name: 'OidcCallback',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Login'
    },
    component: () =>
      import(
        /* webpackChunkName: "oidcCallback" */
        '../views/oidc/Callback.vue'
      )
  },

  {
    path: '/:slug/callback',
    name: 'OidcCallbackWithSlug',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Login'
    },
    component: () =>
      import(
        /* webpackChunkName: "oidcCallback" */
        '../views/oidc/Callback.vue'
      )
  },

  {
    path: '/silent-renew',
    name: 'OidcSilentRenew',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Login'
    },
    component: () =>
      import(
        /* webpackChunkName: "oidcSilentRenew" */
        '../views/oidc/SilentRenew.vue'
      )
  },

  {
    path: '/:slug/promotions',
    name: 'Promotions',
    meta: {
      breadcrumbs: [],
      title: ''
    },
    component: () =>
      import(
        /* webpackChunkName: "promotions" */
        '../views/Promotions/overview.vue'
      )
  },

  {
    path: '/error-403',
    name: 'Error403',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Error 403'
    },
    component: () =>
      import(/* webpackChunkName: "error401" */ '../Error403.vue')
  },

  {
    path: '*',
    name: 'Error404',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Error 404'
    },
    component: () =>
      import(/* webpackChunkName: "error404" */ '../Error404.vue')
  },

  {
    path: '/error-500',
    name: 'Error500',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Error 500'
    },
    component: () =>
      import(/* webpackChunkName: "error500" */ '../Error500.vue')
  },

  ...feedback.routes,
  ...employees.routes,
  ...workflows.routes,
  ...targets.routes,
  ...reportingRoutes.routes
];

const router = new VueRouter({
  routes
});

export default router;
