import api from '@/helpers/api.js';

export default {
  async getErrors() {
    const data = await api.get(`/error-log`);
    return data;
  },

  async download() {
    const file = await api.download(`/error-log/export`);
    return file;
  },

  async addErrorLog(errorData) {
    await api.post('/logs', JSON.stringify(errorData));
  }
};
