import api from '@/helpers/api.js';
import { v4 } from 'uuid';

export default {
  async saveForm(formData, isDraft = false) {
    const payload = {
      id: formData.id,
      correlationId: formData.correlationId,
      name: formData.name,
      questionnaireType: formData.type,
      isDraft: isDraft,
      pages: formData.pages.map((p) => {
        return {
          id: p.id,
          pageIndex: p.pageIndex,
          elements: p.elements.map((e) => {
            return {
              id: e.id,
              index: e.index,
              type: e.type,
              referencedElementName: e.referencedElementName,
              title: e.title,
              subtitle: e.subtitle,
              minValue: e.minValue,
              maxValue: e.maxValue,
              useScale: e.useScale,
              elementValidationMode: e.elementValidationMode,
              allowComment: e.allowComment,
              commentValidationMode: e.commentValidationMode,
              commentFieldType: e.commentFieldType,
              customComponent: e.customComponent,
              anchors: e.labels ?? [],
              items: (e.items ?? []).map((i) => {
                return {
                  id: i.id,
                  title: i.title,
                  subtitle: i.subtitle,
                  index: i.index
                };
              }),
              hasCondition: e.hasCondition,
              condition: e.condition ?? [],
              elements: (e.elements ?? []).map((e2) => {
                return {
                  id: e2.id,
                  index: e2.index,
                  type: e2.type,
                  title: e2.title,
                  subtitle: e2.subtitle,
                  minValue: e2.minValue,
                  maxValue: e2.maxValue,
                  useScale: e2.useScale,
                  elementValidationMode: e2.elementValidationMode,
                  allowComment: e2.allowComment,
                  commentValidationMode: e2.commentValidationMode,
                  commentFieldType: e2.commentFieldType,
                  customComponent: e2.customComponent,
                  anchors: e2.labels ?? [],
                  items: (e2.items ?? []).map((i) => {
                    return {
                      id: i.id,
                      title: i.title,
                      subtitle: i.subtitle,
                      index: i.index
                    };
                  })
                };
              })
            };
          })
        };
      })
    };

    const data = await api.post('/Forms', payload);
    return data;
  },

  async listForms() {
    const data = await api.get('/Forms');
    return data.formsListItems;
  },

  async loadCustomSettings(companySlug) {
    const data = await api.get(`/FormSettings/${companySlug}`);
    return data.result;
  },

  async loadForm(formId) {
    const data = await api.get(`/Forms/${formId}`);
    return data;
  },

  async deleteForm(formId) {
    await api.delete('/Forms', { id: formId, correlationId: v4() });
  },

  async copyForm(formId, newTitle) {
    await api.post(`/Forms/copy`, {
      id: formId,
      newTitle: newTitle,
      correlationId: v4()
    });
  }
};
