import feedbacksService from '@/services/FeedbacksService';

const copyFeedbackData = async (
  context,
  { feedbackData, currentFeedbackId, copyFeedbackId }
) => {
  await feedbacksService.copyFeedbackData(
    feedbackData,
    currentFeedbackId,
    copyFeedbackId
  );
};

const copyProcess = async (context, data) => {
  await feedbacksService.copyProcess(data.processId, data.correlationId);
};

const createFeedback = async (context, feedbackData) => {
  await feedbacksService.createFeedback(feedbackData);
};

const createPdp = async (context, employeeId) => {
  await feedbacksService.createPdp(employeeId);
};

const deleteTableItem = async (context, itemData) => {
  return await feedbacksService.deleteTableItem(itemData);
};

const exportTalkDate = async (context, processId) => {
  return await feedbacksService.exportTalkDate(processId);
};

const getFieldValue = async (context, fieldValueRequest) => {
  const { result } = await feedbacksService.getFieldValue(
    fieldValueRequest.recipientId,
    fieldValueRequest.elementData
  );

  return result;
};

const importArchive = async (context, shorthand) => {
  return await feedbacksService.importArchive(shorthand);
};

const listDevelopmentPositions = () => {
  return feedbacksService.listDevelopmentPositions();
};

const listFeedbacksAsRecipient = async (context, employeeId) => {
  return await feedbacksService.listFeedbacksAsRecipient(employeeId);
};

const listMyFeedbacks = async ({ state }) => {
  if (!state.myFeedbacks) {
    state.myFeedbacks = await feedbacksService.listFeedbacksAsRecipient();
  }

  return state.myFeedbacks;
};

const listFeedbacksAsDonor = async () => {
  return await feedbacksService.listFeedbacksAsDonor();
};

const listFeedbacksFromHierarchy = async () => {
  return await feedbacksService.listFeedbacksFromHierarchy();
};

const listProcessTypes = async (context, targetUserId) => {
  return await feedbacksService.listProcesses(targetUserId);
};

const loadAnonymousFeedback = async (
  { state },
  { feedbackId, accessToken }
) => {
  const data = await feedbacksService.loadAnonymousFeedback(
    feedbackId,
    accessToken
  );

  data.result.id = feedbackId;

  state.currentFeedbackData = data.result;

  return data;
};

const loadFeedback = async ({ state }, feedbackId) => {
  const data = await feedbacksService.loadFeedback(feedbackId);

  state.feedbackInProgress =
    data.result.isInProgress || data.result.isSubmitted;
  state.currentFeedbackData = data.result;
  state.ehgValues[feedbackId] = data.result.ehgValues;

  return data;
};

const loadReviewData = async (context, data) => {
  var jsonData = [];

  const reviewData = await feedbacksService.loadReviewData(
    data.feedbackId,
    data.elementName
  );

  if (reviewData.result !== '') {
    jsonData = JSON.parse(reviewData.result);
  }

  return jsonData;
};

const saveCareerData = async ({ dispatch }, careerData) => {
  if (!careerData.accessToken) {
    dispatch('setFeedbackInProgress', careerData.feedbackId);
  }

  return feedbacksService.saveCareerData(
    careerData.feedbackId,
    careerData.feedbackType,
    careerData.targetPosition,
    careerData.targetDate,
    careerData.formData,
    careerData.accessToken
  );
};

const saveComment = async ({ dispatch }, itemData) => {
  if (itemData.comment !== '' && !itemData.accessToken) {
    dispatch('setFeedbackInProgress', itemData.feedbackId);
  }
  const result = await feedbacksService.saveComment(
    itemData.feedbackId,
    itemData.elementId,
    itemData.itemId,
    itemData.comment,
    itemData.state,
    itemData.accessToken
  );

  return result;
};

const saveMatrixItemValue = async ({ dispatch }, itemData) => {
  if (!itemData.accessToken) {
    dispatch('setFeedbackInProgress', itemData.feedbackId);
  }

  return await feedbacksService.saveMatrixItemValue(
    itemData.feedbackId,
    itemData.elementId,
    itemData.itemId,
    itemData.value,
    itemData.state,
    itemData.accessToken
  );
};

const saveItemValue = async ({ dispatch }, itemData) => {
  if (!itemData.accessToken) {
    dispatch('setFeedbackInProgress', itemData.feedbackId);
  }

  return await feedbacksService.saveItemValue(
    itemData.feedbackId,
    itemData.elementId,
    itemData.value,
    itemData.state,
    itemData.accessToken
  );
};

const saveTalkDate = async (context, talkdateData) => {
  return await feedbacksService.saveTalkDate(talkdateData);
};

const saveTarget = async ({ dispatch }, targetData) => {
  if (targetData.feedbackId && !targetData.accessToken) {
    dispatch('setFeedbackInProgress', targetData.feedbackId);
  }

  return await feedbacksService.saveTarget(targetData);
};

const saveTableItem = async ({ dispatch }, itemData) => {
  if (itemData.columnValues.length > 0) {
    dispatch('setFeedbackInProgress', itemData.feedbackId);
  }

  return await feedbacksService.saveTableItem(itemData);
};

const searchFeedbackValues = async (context, keyword) => {
  return await feedbacksService.searchFeedbackValues(keyword);
};

const setFeedbackInProgress = ({ state }, feedbackId) => {
  if (!state.feedbackInProgress) {
    feedbacksService.setFeedbackInProgress(feedbackId);
    state.feedbackInProgress = false;
  }
};

const submitFeedback = async ({ commit }, feedbackId) => {
  const result = await feedbacksService.submitFeedback(feedbackId);

  commit('clearFeedbacks');

  return result;
};

const approveFeedback = async ({ commit }, feedbackId) => {
  const result = await feedbacksService.approveFeedback(feedbackId);

  commit('clearFeedbacks');

  return result;
};

const vetoFeedback = async ({ commit }, vetoData) => {
  const result = await feedbacksService.vetoFeedback(
    vetoData.feedbackId,
    vetoData.comment
  );

  commit('clearFeedbacks');
  return result;
};

const setProcessStatus = async (context, processData) => {
  return await feedbacksService.setProcessStatus(
    processData.id,
    processData.statusId
  );
};

const setProcessForm = async (context, processData) => {
  return await feedbacksService.setProcessForm(
    processData.id,
    processData.feedbacks[0].formId
  );
};

const getTargets = async (context, userId) => {
  return await feedbacksService.getTargets(userId);
};

const deleteTarget = async (context, targetId) => {
  return await feedbacksService.deleteTarget(targetId);
};

export default {
  approveFeedback,
  copyFeedbackData,
  copyProcess,
  createFeedback,
  createPdp,
  deleteTableItem,
  exportTalkDate,
  getFieldValue,
  importArchive,
  listDevelopmentPositions,
  listFeedbacksAsDonor,
  listFeedbacksAsRecipient,
  listFeedbacksFromHierarchy,
  listMyFeedbacks,
  listProcessTypes,
  loadAnonymousFeedback,
  loadFeedback,
  loadReviewData,
  saveCareerData,
  saveComment,
  saveItemValue,
  saveMatrixItemValue,
  saveTableItem,
  saveTalkDate,
  saveTarget,
  searchFeedbackValues,
  setFeedbackInProgress,
  submitFeedback,
  vetoFeedback,
  setProcessStatus,
  setProcessForm,
  getTargets,
  deleteTarget
};
