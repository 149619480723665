export default {
  register(hub, connection, startedPromise) {
    connection.on('FeedbackProcessCreated', (processId, correlationId) => {
      hub.$emit('feedback-process-created', {
        processId,
        correlationId
      });
    });

    connection.on('FeedbackProcessUpdated', (correlationId) => {
      hub.$emit('feedback-process-updated', correlationId);
    });

    hub.enterCreateProcessGroup = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('JoinCreateProcessGroup', correlationId))
        .catch(console.error);
    };

    hub.leaveCreateProcessGroup = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('LeaveCreateProcessGroup', correlationId))
        .catch(console.error);
    };

    hub.enterUpdateProcessGroup = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('JoinUpdateProcessGroup', correlationId))
        .catch(console.error);
    };

    hub.leaveUpdateProcessGroup = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('LeaveUpdateProcessGroup', correlationId))
        .catch(console.error);
    };
  }
};
