export default {
  register(hub, connection, startedPromise) {
    connection.on(
      'PromotionProcessAdded',
      (correlationId, promotionUserData) => {
        hub.$emit('promotion-process-added', {
          correlationId,
          promotionUserData
        });
      }
    );

    hub.joinPromotionsGroup = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('JoinPromotionsGroup', correlationId))
        .catch(console.error);
    };

    hub.leavePromotionsGroup = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('LeavePromotionsGroup', correlationId))
        .catch(console.error);
    };
  }
};
