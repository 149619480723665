import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import feedbackSubmittedHub from './feedback-submitted-hub.js';
import feedbacksHub from './feedbacks-hub.js';
import formsHub from './forms-hub.js';
import organizationHub from './organization-hub.js';
import promotionHub from './promotion-hub.js';
import usersHub from './users-hub.js';
import processOverviewHub from './process-overview-hub.js';

export default {
  install(Vue) {
    const connection = new HubConnectionBuilder()
      .withUrl(process.env.VUE_APP_HP_DPT_API + '/hubs/hub')
      .configureLogging(LogLevel.Warning)
      .build();

    let startedPromise = null;

    function start() {
      startedPromise = connection.start().catch((err) => {
        console.error('Failed to connect with hub', err);
        return new Promise((resolve, reject) =>
          setTimeout(() => start().then(resolve).catch(reject), 5000)
        );
      });
      return startedPromise;
    }
    connection.onclose(() => start());

    start();

    // use new Vue instance as an event bus
    const hub = new Vue();
    // every component will use this.$hub to access the event bus
    Vue.prototype.$hub = hub;

    feedbackSubmittedHub.register(hub, connection, startedPromise);
    feedbacksHub.register(hub, connection, startedPromise);
    formsHub.register(hub, connection, startedPromise);
    organizationHub.register(hub, connection, startedPromise);
    promotionHub.register(hub, connection, startedPromise);
    usersHub.register(hub, connection, startedPromise);
    processOverviewHub.register(hub, connection, startedPromise);
  }
};
