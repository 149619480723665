<template>
  <div>
    <v-system-bar color="deep-purple darken-3"></v-system-bar>

    <v-app-bar flat app fixed clipped-left>
      <img
        :src="require(`@/assets/${organizationSlug}-logo.png`)"
        style="height: 80px"
        v-if="$vuetify.breakpoint.mdAndUp && organizationSlug"
      />

      <v-spacer></v-spacer>

      <theme-switch
        v-if="$vuetify.breakpoint.mdAndUp"
        :custom-colors="false"
      ></theme-switch>
    </v-app-bar>

    <v-main>
      <v-row>
        <v-col cols="12">
          <v-container fluid class="pa-2 pt-0 pa-sm-2 pt-sm-0 pa-md-6 pt-md-0">
            <page-title></page-title>
          </v-container>
          <v-container fluid class="pa-2 pt-0 pa-sm-2 pt-sm-0 pa-md-6 pt-md-0">
            <slot />
          </v-container> </v-col
      ></v-row>
    </v-main>
  </div>
</template>

<script>
import pageTitle from '@/components/main/PageTitle';
import themeSwitch from '@/components/theme-switch';

import AuthService from '@/services/AuthService.js';
import FileService from '@/services/FileService.js';

import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      settingsMenu: false,
      languageMenu: false,
      authService: null,
      group: null,
      group2: null,
      languages: [
        { value: 'de-DE', label: 'Deutsch' },
        { value: 'en-US', label: 'English' }
      ]
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      currentOrganization: 'currentOrganization',
      languageKey: 'languageKey'
    }),

    organizationSlug() {
      return this.currentOrganization?.slug;
    },

    logoUrl() {
      return FileService.getPublicFilePath(`${this.organizationSlug}-logo.png`);
    }
  },

  mounted() {
    this.authService = new AuthService();
  },

  methods: {
    ...mapActions({
      setLanguage: 'setLanguage',
      unimpersonateUser: 'unimpersonateUser'
    }),

    async logout() {
      if (this.isImpersonated) {
        this.unimpersonateUser();

        setTimeout(async () => {
          this.$hub.leaveOrganization(this.currentOrganization.id);
          await this.authService.logout();
        }, 200);
      } else {
        this.$hub.leaveOrganization(this.currentOrganization.id);
        await this.authService.logout();
      }
    },

    selectLanguage(languageKey) {
      this.selectedLanguage = languageKey;
    },

    async stopImpersonating() {
      try {
        await this.unimpersonateUser();
      } catch (e) {
        console.log(e);
      }

      if (this.$route.name.toLowerCase() !== 'Dashboard') {
        this.$router.push({ name: 'Dashboard' });
      }

      setTimeout(() => window.location.reload(), 1000);
    }
  },

  components: {
    pageTitle,
    themeSwitch
  }
};
</script>
FulltextSearch

<style lang="scss" scoped>
.avatar-title {
  font-weight: bold !important;
  font-size: 1rem !important;
}

.v-list-item__subtitle {
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.overline {
  font-size: 0.875rem !important;
}
</style>
