export const feedback = {
  routes: [
    {
      path: '/:slug/my-feedbacks',
      name: 'MyFeedbacks',
      meta: {
        breadcrumbs: [
          { label: 'feedbacks.feedbacks', icon: 'comment' },
          { label: 'feedbacks.myFeedbacks' }
        ],
        title: 'feedbacks.myFeedbacksOverview'
      },
      component: () =>
        import(
          /* webpackChunkName: "my-feedbacks" */ '../views/Feedbacks/my-feedbacks.vue'
        )
    },

    {
      path: '/:slug/feedback/:id',
      name: 'FeedbackForm',
      meta: {
        breadcrumbs: [
          { label: 'feedbacks.feedbacks', icon: 'comment' },
          { label: 'feedbacks.myFeedbacks' }
        ],
        title: 'feedbacks.myFeedbacksOverview'
      },
      component: () =>
        import(
          /* webpackChunkName: "feedback-form" */ '../views/Feedbacks/feedback-form.vue'
        )
    },

    {
      path: '/:slug/feedback/anonymous/:id/:accessToken',
      name: 'AnonymousFeedbackForm',
      meta: {
        breadcrumbs: [
          { label: 'feedbacks.feedbacks', icon: 'comment' },
          { label: 'feedbacks.myFeedbacks' }
        ],
        title: 'feedbacks.myFeedbacksOverview'
      },
      component: () =>
        import(
          /* webpackChunkName: "feedback-form" */
          '../views/Feedbacks/anonymous-feedback-form.vue'
        )
    },

    {
      path: '/:slug/print/:id',
      name: 'PrintFeedback',
      meta: {
        layout: 'print'
      },
      component: () =>
        import(
          /* webpackChunkName: "print-feedback" */ '../views/Feedbacks/print.vue'
        )
    },

    {
      path: '/:slug/print/anonymous/:id/:accessToken',
      name: 'AnonymousPrintFeedback',
      meta: {
        layout: 'print'
      },
      component: () =>
        import(
          /* webpackChunkName: "print-feedback" */ '../views/Feedbacks/print.vue'
        )
    },

    {
      path: '/:slug/feedbacks-as-donor',
      name: 'FeedbacksAsDonor',
      meta: {
        breadcrumbs: [{ label: 'feedbacks.feedbacksAsDonor' }],
        title: 'feedbacks.feedbacksOverview'
      },
      props: (route) => ({
        status: route.query.status,
        type: route.query.type,
        keyword: route.query.kw,
        indirect: route.query.indirect
      }),
      component: () =>
        import(
          /* webpackChunkName: "feedbacks-as-donor" */ '../views/Feedbacks/feedbacks-as-donor.vue'
        )
    }
  ]
};
