<template>
  <v-app>
    <loading-layer
      v-if="!isAnonymous"
      :value="authorizationProgress"
      :info="authorizationInfo"
    />

    <component :is="layout">
      <router-view></router-view>
    </component>
  </v-app>
</template>

<script>
import loadingLayer from '@/components/misc/loading-layer';
import defaultLayout from '@/layouts/Default';
import printLayout from '@/layouts/Print';
import anonymousLayout from '@/layouts/Anonymous';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'App',

  components: {
    loadingLayer,
    defaultLayout,
    printLayout,
    anonymousLayout
  },

  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      currentOrganization: 'currentOrganization',
      authorizationProgress: 'authorizationProgress',
      authorizationInfo: 'authorizationInfo',
      languageKey: 'languageKey'
    }),

    isAnonymous() {
      return (
        this.$route.params.accessToken !== null &&
        this.$route.params.accessToken !== undefined
      );
    },

    layout() {
      if (this.isAnonymous) {
        return 'anonymousLayout';
      }

      return (this.$route.meta.layout || 'default') + 'Layout';
    }
  },

  async beforeMount() {
    if (
      !/callback/.test(window.location.href) &&
      !/silent-renew/.test(window.location.href) &&
      !/error-403/.test(window.location.href) &&
      !/anonymous/.test(window.location.href)
    ) {
      await this.initializeSession();
    }

    this.$hub.$on('workflows-modified', this.getWorkflows);
    this.$hub.$on('dashboard-processes-updated', this.updateDashboardProcesses);
    this.$hub.$on('dashboard-dates-updated', this.updateDashboardDates);
    this.$hub.$on('dashboard-tasks-updated', this.updateDashboardTasks);
  },

  beforeDestroy() {
    this.$hub.leaveOrganization(this.currentOrganization.id);
    this.$hub.leaveUserGroup(this.currentUser.id);
    this.$hub.$off('workflows-modified');
    this.$hub.$off('dashboard-processes-updated');
    this.$hub.$off('dashboard-dates-updated');
    this.$hub.$off('dashboard-tasks-updated');
  },

  watch: {
    currentOrganization(newOrganization, oldOrganization) {
      this.$i18n.locale = this.languageKey.substring(
        0,
        this.languageKey.indexOf('-')
      );

      if (oldOrganization) {
        this.$hub.leaveOrganization(oldOrganization.id);
      }
      if (newOrganization) {
        this.$hub.joinOrganization(newOrganization.id);
        const vm = this;
        this.getThemeColors(newOrganization.slug).then(function (
          themeSettings
        ) {
          if (themeSettings !== null) {
            vm.$vuetify.theme.themes.light.primary =
              themeSettings.light.primary;
            vm.$vuetify.theme.themes.light.secondary =
              themeSettings.light.secondary;
            vm.$vuetify.theme.themes.light.accent = themeSettings.light.accent;
            vm.$vuetify.theme.themes.light.background =
              themeSettings.light.background;
            vm.$vuetify.theme.themes.dark.primary = themeSettings.dark.primary;
            vm.$vuetify.theme.themes.dark.secondary =
              themeSettings.dark.secondary;
            vm.$vuetify.theme.themes.dark.accent = themeSettings.dark.accent;
          }
        });
        document.title = `${newOrganization.slug.toUpperCase()} - Development & Performance Tool`;
      } else {
        document.title = `hidden professionals - Development & Performance Tool`;
      }
    },

    currentUser(newUser, oldUser) {
      if (oldUser) {
        this.$hub.leaveUserGroup(oldUser?.id);
      }
      if (newUser) {
        this.$hub.joinUserGroup(newUser.id);
      }
    }
  },

  methods: {
    ...mapActions({
      initializeSession: 'initializeSession',
      getWorkflows: 'fetchWorkflowSettings',
      updateDashboardProcesses: 'dashboard/updateProcesses',
      updateDashboardDates: 'dashboard/updateDates',
      updateDashboardTasks: 'dashboard/updateTasks',
      getThemeColors: 'getThemeColors'
    })
  }
};

import('@/assets/styles/imports.scss');
</script>

<style>
.theme--light.v-application {
  background-color: var(--v-background-base, white) !important;
}
.v-toolbar__content {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}
</style>
