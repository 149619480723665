export default {
  register(hub, connection, startedPromise) {
    connection.on(
      'WorkflowSettingsModified',
      (workflowSettingsId, organizationId) => {
        hub.$emit('workflows-modified', {
          workflowSettingsId,
          organizationId
        });
      }
    );

    connection.on('FeedbackProcessesUpdated', () => {
      hub.$emit('dashboard-processes-updated');
    });

    hub.joinOrganization = (organizationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('JoinOrganizationGroup', organizationId))
        .catch(console.error);
    };

    hub.leaveOrganization = (organizationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('LeaveOrganizationGroup', organizationId))
        .catch(console.error);
    };
  }
};
