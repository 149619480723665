export default {
  register(hub, connection, startedPromise) {
    connection.on('FixedDatesUpdated', () => {
      hub.$emit('dashboard-dates-updated');
    });

    connection.on('FeedbackTasksUpdated', () => {
      hub.$emit('dashboard-tasks-updated');
    });

    connection.on('RecipientProcessesUpdated', () => {
      hub.$emit('recipient-processes-updated');
    });

    connection.on('DonorProcessesUpdated', () => {
      hub.$emit('donor-processes-updated');
    });

    hub.joinUserGroup = (userId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('JoinUserGroup', userId))
        .catch(console.error);
    };

    hub.leaveUserGroup = (userId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('LeaveUserGroup', userId))
        .catch(console.error);
    };
  }
};
