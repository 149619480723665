import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import i18n from './i18n';
import store from './store';
import VueApexCharts from 'vue-apexcharts';

import { filters } from '@/filters/filters.js';

Vue.filter('highlight', filters.highlight);

import { localizationMixin } from '@/mixins/localization.js';
Vue.mixin(localizationMixin);

import Hub from '@/plugins/hubs/hub.js';
Vue.use(Hub);

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.config.productionTip = false;

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        if (typeof vnode.context[binding.expression] === 'function') {
          vnode.context[binding.expression](event);
        }
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});

new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: (h) => h(App)
}).$mount('#app');
