export default {
  register(hub, connection, startedPromise) {
    connection.on('FeedbackSubmitted', (feedbackId, correlationId) => {
      hub.$emit('feedback-submitted', {
        feedbackId,
        correlationId
      });
    });

    hub.joinFeedbackSubmittedGroup = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() =>
          connection.invoke('JoinFeedbackSubmittedGroup', correlationId)
        )
        .catch(console.error);
    };

    hub.leaveFeedbackSubmittedGroup = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() =>
          connection.invoke('JoinFeedbackSubmittedGroup', correlationId)
        )
        .catch(console.error);
    };
  }
};
