import errorLogService from '@/services/ErrorLogService.js';

const fetchErrors = async ({ state }) => {
  const errors = await errorLogService.getErrors();
  state.errors = errors;

  return errors;
};

const logClientError = async (context, errorData) => {
  await errorLogService.addErrorLog(errorData);
};
      
export default {
  fetchErrors,
  logClientError
};
