export const employees = {
  routes: [
    {
      path: '/:slug/my-employees',
      name: 'MyEmployees',
      meta: {
        breadcrumbs: [
          { label: 'employeeOverview.myEmployees', icon: 'account-multiple' },
          { label: 'overview' }
        ],
        title: 'employeeOverview.myEmployeesOverview'
      },
      component: () =>
        import(
          /* webpackChunkName: "my-employees" */
          '../views/Employees/my-employees.vue'
        )
    },

    {
      path: '/:slug/employee-details/:shorthand',
      name: 'EmployeeDetails',
      meta: {
        breadcrumbs: [
          { label: 'employeeOverview.myEmployees', icon: 'account-multiple' },
          { label: 'overview' },
          { label: 'employeeDetails.breadcrumb' }
        ],
        title: 'employeeDetails.breadcrumb'
      },
      component: () =>
        import(
          /* webpackChunkName: "employee-details" */
          '../views/Employees/employee-details.vue'
        )
    },

    {
      path: '/:slug/profile',
      name: 'Profile',
      meta: {
        breadcrumbs: [],
        title: 'employeeDetails.myProfile'
      },
      component: () =>
        import(
          /* webpackChunkName: "my-profile" */
          '../views/Employees/employee-details.vue'
        )
    }
  ]
};
