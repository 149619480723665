import AuthService from '@/services/AuthService.js';
const apiUrl = process.env.VUE_APP_HP_DPT_API;
import { errorCodes } from '@/enums/errorCodes.js';

export default {
  async download(path, params) {
    var authService = new AuthService();
    var user = await authService.getUser();

    if (user?.access_token) {
      var requestUrl = new URL(`${apiUrl}${path}`);
      if (params) {
        Object.keys(params).forEach((key) =>
          requestUrl.searchParams.append(key, params[key])
        );
      }

      var response = await fetch(requestUrl, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + user.access_token
        })
      });

      if (response.ok) {
        const blob = response.blob();
        return blob;
      } else if (response.status === 401) {
        await authService.login();
      } else if (response.status === 403) {
        window.location.href = '/#/error-403';
      } else if (response.status === 404) {
        throw new Error('not found');
      } else {
        // TODO: handle exception
      }
    } else {
      await authService.login();
    }

    return null;
  },

  async get(path, params) {
    if (/anonymous/.test(window.location.href)) {
      return this.getAnonymous(path, params);
    }

    var authService = new AuthService();
    var user = await authService.getUser();

    if (user?.access_token) {
      var requestUrl = new URL(`${apiUrl}${path}`);
      if (params) {
        Object.keys(params).forEach((key) =>
          requestUrl.searchParams.append(key, params[key])
        );
      }

      var response = await fetch(requestUrl, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + user.access_token
        })
      });

      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        await authService.login();
      } else if (response.status === 403) {
        window.location.href = '/#/error-403';
      } else {
        // TODO: handle exception
      }
    } else {
      await authService.login();
    }

    return null;
  },

  async getAnonymous(path, params) {
    var requestUrl = new URL(`${apiUrl}${path}`);
    if (params) {
      Object.keys(params).forEach((key) =>
        requestUrl.searchParams.append(key, params[key])
      );
    }

    var response = await fetch(requestUrl, {
      method: 'get'
    });

    if (response.ok) {
      return response.json();
    } else if (response.status === 403) {
      window.location.href = '/#/error-403';
    } else {
      // TODO: handle exception
    }
  },

  async put(path, params) {
    var authService = new AuthService();
    var user = await authService.getUser();

    if (user?.access_token) {
      var requestUrl = new URL(`${apiUrl}${path}`);

      var requestOptions = {
        method: 'put',
        headers: new Headers({
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + user.access_token
        })
      };

      if (params) {
        requestOptions.body = JSON.stringify(params);
      }

      var response = await fetch(requestUrl, requestOptions);

      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        authService.login();
      } else if (response.status === 403) {
        window.location.href = '/#/error-403';
      } else {
        // TODO: handle exception
        // window.location.href = '/#/error-500';
      }
    } else {
      authService.login();
    }

    return null;
  },

  async post(path, params) {
    var authService = new AuthService();
    var user = await authService.getUser();

    if (user?.access_token) {
      var requestUrl = new URL(`${apiUrl}${path}`);

      var requestOptions = {
        method: 'post',
        headers: new Headers({
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + user.access_token
        })
      };

      if (params) {
        requestOptions.body = JSON.stringify(params);
      }

      var response = await fetch(requestUrl, requestOptions);

      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        authService.login();
      } else if (response.status === 403) {
        window.location.href = '/#/error-403';
      } else {
        // TODO: handle exception
        // window.location.href = '/#/error-500';
        return {
          errorCode: errorCodes.errorCodes.UNEXPECTED_EXCEPTION
        };
      }
    } else {
      authService.login();
    }

    return null;
  },

  async postAnonymous(path, params) {
    var requestUrl = new URL(`${apiUrl}${path}`);

    var requestOptions = {
      method: 'post',
      headers: new Headers({
        'Content-type': 'application/json'
      })
    };

    if (params) {
      requestOptions.body = JSON.stringify(params);
    }

    var response = await fetch(requestUrl, requestOptions);

    if (response.ok) {
      return response.json();
    } else if (response.status === 403) {
      window.location.href = '/#/error-403';
    } else {
      // TODO: handle exception
    }

    return null;
  },

  async postFile(path, file) {
    var authService = new AuthService();
    var user = await authService.getUser();

    if (user?.access_token) {
      var requestUrl = new URL(`${apiUrl}${path}`);

      const formData = new FormData();
      formData.append('file', file);

      var requestOptions = {
        method: 'post',
        headers: new Headers({
          Authorization: 'Bearer ' + user.access_token
        }),
        body: formData
      };

      var response = await fetch(requestUrl, requestOptions);

      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        authService.login();
      } else if (response.status === 403) {
        window.location.href = '/#/error-403';
      } else {
        // TODO: handle exception
        // window.location.href = '/#/error-500';
      }
    } else {
      authService.login();
    }

    return null;
  },

  async delete(path, params) {
    var authService = new AuthService();
    var user = await authService.getUser();

    if (user?.access_token) {
      var requestUrl = new URL(`${apiUrl}${path}`);
      if (params) {
        Object.keys(params).forEach((key) =>
          requestUrl.searchParams.append(key, params[key])
        );
      }

      var response = await fetch(requestUrl, {
        method: 'delete',
        headers: new Headers({
          Authorization: 'Bearer ' + user.access_token
        })
      });

      if (!response.ok) {
        // TODO: handle exceptions
      } else if (response.status === 401) {
        authService.login();
      } else if (response.status === 403) {
        window.location.href = '/#/error-403';
      }
    } else {
      authService.login();
    }

    return null;
  }
};
