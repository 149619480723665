export default {
  register(hub, connection, startedPromise) {
    connection.on('FormSaved', (formId, correlationId) => {
      hub.$emit('form-saved', { formId, correlationId });
    });

    connection.on('FormDeleted', (formId, correlationId) => {
      hub.$emit('form-deleted', { formId, correlationId });
    });

    connection.on('FormCopied', (formId, copyId, newTitle, correlationId) => {
      hub.$emit('form-copied', {
        formId,
        copyId,
        newTitle,
        correlationId
      });
    });

    hub.enterCreateFormGroup = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('JoinCreateFormGroup', correlationId))
        .catch(console.error);
    };

    hub.leaveCreateFormGroup = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('LeaveCreateFormGroup', correlationId))
        .catch(console.error);
    };
  }
};
