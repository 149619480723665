import api from '@/helpers/api.js';
import { feedbackEnums } from '@/enums/feedbacks.js';
// import { v4 as uuidv4 } from 'uuid';
const apiUrl = process.env.VUE_APP_ARCHIVE_API_URL;

export default {
  async approveFeedback(feedbackId) {
    return await api.put('/Feedbacks/Approve', {
      feedbackId
    });
  },

  async copyFeedbackData(feedbackData, currentFeedbackId, copyFeedbackId) {
    const previousFeedbackData = await this.loadFeedback(copyFeedbackId);

    // create a list of all elements from the previous feedback
    const elements = [];
    for (const page of previousFeedbackData.result.formContent.pages) {
      for (const el of page.elements) {
        elements.push(el);
      }
    }

    for (const page of feedbackData.formContent.pages) {
      for (const el of page.elements) {
        const previousElement = elements.find((x) => x.id === el.id || x.id === el.copyElementId);
        if (previousElement) {
          if (el.value < 0 && previousElement.value >= 0) {
            el.value = previousElement.value;
          }

          if (!el.comment) {
            el.commentState = feedbackEnums.valueState.COPIED;
            el.comment = previousElement.comment;

            this.saveComment(
              currentFeedbackId,
              el.id,
              null,
              previousElement.comment,
              feedbackEnums.valueState.COPIED
            );
          }

          if (el.items?.length > 0) {
            for (const item of el.items) {
              const previousItem = previousElement.items.find(
                (x) => x.id === item.id || x.id === item.copyElementId
              );

              if (previousItem) {
                if (item.value < 0 && previousItem.value >= 0) {
                  item.value = previousItem.value;
                  item.valueState = feedbackEnums.valueState.COPIED;

                  this.saveMatrixItemValue(
                    currentFeedbackId,
                    el.id,
                    item.id,
                    previousItem.value,
                    feedbackEnums.valueState.COPIED
                  );
                }

                if (!item.comment) {
                  item.commentState = feedbackEnums.valueState.COPIED;
                  item.comment = previousItem.comment;

                  this.saveComment(
                    currentFeedbackId,
                    el.id,
                    item.id,
                    previousItem.comment,
                    feedbackEnums.valueState.COPIED
                  );
                }
              }
            }
          }

          if (el.elements?.length > 0) {
            for (const subElement of el.elements) {
              const previousItem = previousElement.elements.find(
                (x) => x.id === subElement.id || x.id === subElement.copyElementId
              );

              if (previousItem) {
                if (subElement.value < 0 && previousItem.value >= 0) {
                  subElement.value = previousItem.value;
                }

                if (!subElement.comment) {
                  subElement.commentState = feedbackEnums.valueState.COPIED;
                  subElement.comment = previousItem.comment;

                  this.saveComment(
                    currentFeedbackId,
                    subElement.id,
                    null,
                    previousItem.comment,
                    feedbackEnums.valueState.COPIED
                  );
                }
              }
            }
          }
        }
      }
    }
  },

  copyProcess(processId, correlationId) {
    api.post(`/Feedbacks/CopyProcess`, { processId, correlationId });
  },

  createFeedback(feedbackData) {
    api.post('/Feedbacks', feedbackData);
  },

  createPdp(employeeId) {
    api.post('/Pdp/create', employeeId);
  },

  async deleteTableItem(itemData) {
    return await api.delete(`/FeedbackTableItem`, itemData);
  },

  async exportTalkDate(processId) {
    return await api.get(`/Feedbacks/ExportTalkDate`, {
      processId: processId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  },

  async getFieldValue(recipientId, elementData) {
    for (let i = 0; i < elementData.length; i++) {
      elementData[i].recipientId = recipientId;
    }

    return await api.post(`/Feedbacks/fieldValue`, elementData);
  },

  async importArchive(shorthand) {
    var shorthandEncoded = window.btoa(shorthand);

    const requestUrl = `${apiUrl}/archive/${shorthandEncoded}`;
    const response = await fetch(requestUrl, {
      method: 'get'
    }).catch(function (error) {
      console.log(error);
      return {
        isAvailable: false
      };
    });

    if (response.ok) {
      const json = await response.json();
      json.conclusionUrl = apiUrl + json.conclusionUrl;
      json.selfEvaluationUrl = apiUrl + json.selfEvaluationUrl;
      json.supervisorEvaluationUrl = apiUrl + json.supervisorEvaluationUrl;
      json.comparisonUrl = apiUrl + json.comparisonUrl;

      return json;
    } else {
      return {
        isAvailable: false
      };
    }
  },

  async listDevelopmentPositions() {
    const data = await api.get(`/CareerDevelopment/GetPositions`);
    return data.positions;
  },

  async listFeedbackStatusOptions() {
    return await api.get(`/Feedbacks/GetStatusOptions`);
  },

  async listFeedbacksAsRecipient(employeeId) {
    let data = null;

    if (employeeId) {
      data = await api.get(`/Feedbacks/list/recipient/${employeeId}`);
    } else {
      data = await api.get(`/Feedbacks/list/recipient`);
    }

    return data.result;
  },

  async listFeedbacksAsDonor() {
    let data = await api.get(`/Feedbacks/list/donor`);
    return data.result;
  },

  async listFeedbacksFromHierarchy() {
    let data = await api.get(`/Feedbacks/list/hierarchy`);
    return data.result;
  },

  async listProcesses(targetUserId) {
    let data = await api.get(`/Process/${targetUserId}`);
    return data;
  },

  async loadAnonymousFeedback(feedbackId, accessToken) {
    return await api.get(
      `/Feedbacks/anonymous?feedbackId=${feedbackId}&accessToken=${accessToken}`
    );
  },

  async loadFeedback(feedbackId) {
    return await api.get(`/Feedbacks/${feedbackId}`);
  },

  async loadReviewData(feedbackId, elementName) {
    return api.get('/feedbacks/values/GetReviewData', {
      feedbackId,
      elementName
    });
  },

  async saveCareerData(
    feedbackId,
    feedbackType,
    targetPosition,
    targetDate,
    formData,
    accessToken
  ) {
    let postMethod = api.post;
    if (accessToken) {
      postMethod = api.postAnonymous;
    }

    return await postMethod(`/CareerDevelopment`, {
      feedbackId,
      feedbackType,
      targetPosition,
      targetDate,
      Data: JSON.stringify(formData)
    });
  },

  async saveComment(
    feedbackId,
    elementId,
    itemId,
    comment,
    state,
    accessToken
  ) {
    let postMethod = api.post;
    if (accessToken) {
      postMethod = api.postAnonymous;
    }

    return await postMethod(`/FeedbackComments`, {
      feedbackId,
      elementId,
      itemId,
      comment,
      state,
      accessToken
    });
  },

  async saveTableItem(itemData) {
    return await api.post(`/FeedbackTableItem`, itemData);
  },

  async saveTalkDate(talkDateData) {
    return await api.post(`/FeedbackTalkDate`, talkDateData);
  },

  async saveTarget(targetData) {
    let postMethod = api.post;
    if (targetData.accessToken) {
      postMethod = api.postAnonymous;
    }

    return await postMethod(`/Feedbacks/Targets/SaveTarget`, targetData);
  },

  async saveMatrixItemValue(
    feedbackId,
    elementId,
    itemId,
    value,
    state,
    accessToken
  ) {
    let postMethod = api.post;
    if (accessToken) {
      postMethod = api.postAnonymous;
    }

    return await postMethod(`/feedbackValues/matrix`, {
      feedbackId,
      elementId,
      itemId,
      value,
      state,
      accessToken
    });
  },

  async saveItemValue(feedbackId, elementId, value, state, accessToken) {
    let postMethod = api.post;
    if (accessToken) {
      postMethod = api.postAnonymous;
    }

    return await postMethod(`/feedbackValues/item`, {
      feedbackId,
      elementId,
      value,
      state,
      accessToken
    });
  },

  async searchFeedbackValues(keyword) {
    return await api.get(`/fulltextsearch/${keyword}`);
  },

  setFeedbackInProgress(feedbackId) {
    api.put(`/Feedbacks/SetInProgress`, {
      feedbackId
    });
  },

  async submitFeedback(feedbackId) {
    return await api.post('/Feedbacks/Submit', {
      feedbackId
    });
  },

  async vetoFeedback(feedbackId, comment) {
    return await api.put('/Feedbacks/Veto', {
      feedbackId,
      comment
    });
  },

  async setProcessStatus(processId, statusId) {
    return await api.post('/Feedbacks/SetProcessStatus', {
      processId,
      statusId
    });
  },

  async setProcessForm(processId, formId) {
    return await api.post('/Feedbacks/SetProcessForm', {
      processId,
      formId
    });
  },

  async getTargets(userId) {
    return await api.get('/Feedbacks/Targets/GetTargets', { userId });
  },

  async deleteTarget(targetId) {
    return await api.post(`/Feedbacks/Targets/DeleteTarget/${targetId}`);
  }
};
